import React from 'react'
import {Link} from 'gatsby'
import '../components/ContactForm.css'
import Layout from '../components/Layout'

const ContactSuccessPage = () => (
  <Layout>
    <main className="Content">
      <div className="wrap">
        <p>
          Thank you for your message!
        </p>
        <p>
          <Link className="link" to="/">Back to Home</Link>
        </p>
      </div>
    </main>
  </Layout>
);

export default ContactSuccessPage
